.index_landing-page-layout__JAxOE.index_landing-page-layout-overwrite__1bvum {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #f2f4f5;
  display: flex;
  flex-flow: column nowrap;
}
@supports (-webkit-touch-callout: none) {
  .index_landing-page-layout__JAxOE.index_landing-page-layout-overwrite__1bvum {
    min-height: -webkit-fill-available;
  }
}
.index_landing-page-layout__JAxOE.index_landing-page-layout-overwrite__1bvum .index_landing-v3-wrapper__maBf4 {
  min-height: 1320px;
  height: 1320px;
  background-image: url("/newimages/landing/landing_topbg.png?20240730");
}
.index_bg-class___8Mfp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.index_error-content-class__U_J8x {
  margin-left: 106px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.index_error-content-wrapper__4_wT2 {
  width: 480px;
}
.index_subtitle-class__DcBAu {
  color: var(--Black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.index_content-button__aeuBW {
  display: inline-block;
  cursor: pointer;
  width: auto;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  padding: 8px 12px;
  background: var(--Green, #00f0a0);
  color: var(--Black, #000);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 123.077% */
  margin-bottom: 24px;
  text-decoration: none;
}
.index_content-divider__SuIem {
  height: 1px;
  background: var(--Grey, #f2f4f5);
  margin-bottom: 24px;
}
.index_subtitle-desc-calss__iwvxq {
  color: var(--Black, #000);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 123.077% */
  margin-bottom: 24px;
}
.index_desc-content-class__7hk78 {
  margin-top: 24px;
}
.index_desc-class__R92Ht {
  color: var(--black-45, rgba(0, 0, 0, 0.45));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.index_footer-title__bVtkh {
  color: var(--Black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}
.index_footer-item__yp1WP {
  color: var(--Black, #000);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
.index_footer-item__yp1WP:hover {
  color: var(--Black, #000);
}
.index_desc-email-class__oN_XY {
  color: var(--black-45, rgba(0, 0, 0, 0.45));
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
}
.index_desc-email-class__oN_XY:hover {
  color: var(--black-3, #000);
}
.index_icon-class__KuwRo {
  margin-top: 64px;
}
.index_mobile-bg-class__bmd_n {
  overflow: hidden;
}
.index_mobile-bg-class__bmd_n .index_icon-right-class__H1rNE {
  margin: 24px 0 32px;
}
.index_mobile-bg-class__bmd_n .index_mobile-content-wrapper__4RwcN {
  padding: 0 24px;
}
.index_mobile-bg-class__bmd_n .index_mobile-content-wrapper__4RwcN .index_subtitle-class__DcBAu {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 120% */
}
.index_mobile-bg-class__bmd_n .index_mobile-content-wrapper__4RwcN .index_subtitle-desc-calss__iwvxq {
  color: var(--Black, #000);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 123.077% */
}
.index_mobile-bg-class__bmd_n .index_mobile-content-wrapper__4RwcN .index_footer-title__bVtkh {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}
.index_mobile-bg-class__bmd_n .index_mobile-content-wrapper__4RwcN .index_footer-item__yp1WP {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
.index_mobile-bg-class__bmd_n .index_mobile-content-wrapper__4RwcN .index_icon-class__KuwRo {
  margin-top: 32px;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_a184c8';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_a184c8';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_a184c8 {font-family: '__Inter_a184c8', '__Inter_Fallback_a184c8';font-style: normal
}

