@import "@/styles/varibles.less";

.landing-page-layout.landing-page-layout-overwrite {
  width: 100%;
  min-height: 100vh;
  overflow: auto;

  background: #f2f4f5;
  display: flex;
  flex-flow: column nowrap;

  .landing-v3-wrapper {
    min-height: 1320px;
    height: 1320px;
    background-image: url("/newimages/landing/landing_topbg.png?20240730");
  }
}

.bg-class {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-content-class {
  margin-left: 106px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-content-wrapper {
  width: 480px;
}

.subtitle-class {
  color: var(--Black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.content-button {
  display: inline-block;
  cursor: pointer;
  width: auto;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  padding: 8px 12px;
  background: var(--Green, #00f0a0);
  color: var(--Black, #000);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  margin-bottom: 24px;

  text-decoration: none;
}
.content-divider {
  height: 1px;
  background: var(--Grey, #f2f4f5);
  margin-bottom: 24px;
}
.subtitle-desc-calss {
  color: var(--Black, #000);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  margin-bottom: 24px;
}
.desc-content-class {
  margin-top: 24px;
}
.desc-class {
  color: var(--black-45, rgba(0, 0, 0, 0.45));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.footer-title {
  color: var(--Black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
.footer-item {
  color: var(--Black, #000);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  &:hover {
    color: var(--Black, #000);
  }
}
.desc-email-class {
  color: var(--black-45, rgba(0, 0, 0, 0.45));
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-inline-start: 4px;
  &:hover {
    color: var(--black-3, #000);
  }
}
.icon-class {
  margin-top: 64px;
}
.mobile-bg-class {
  overflow: hidden;
  .icon-right-class {
    margin: 24px 0 32px;
  }
  .mobile-content-wrapper {
    padding: 0 24px;
    .subtitle-class {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }

    .subtitle-desc-calss {
      color: var(--Black, #000);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
    }
    .footer-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
    .footer-item {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
    .icon-class {
      margin-top: 32px;
    }
  }
}
